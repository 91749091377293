<template>
	<div>
		<b-overlay :show="!editableTag == false && $store.state.loader" rounded="sm">
			<b-list-group-item :id="'tagline-'+tag.id">
				<b-row>
					<b-col v-if="tag.depth > 0" :cols="tag.depth * 5">
					</b-col>
					<b-col class="text-left">
						<b-row>
							<b-col cols="120">
								<template v-if="editableTag">
									<span v-bind:style="tag.children.length > 0 ? 'font-weight: bold;' : ''">
										{{ tag.prefix }}
									</span><br>
									Nimetus: <br>
									<b-input v-model="tag.name"></b-input>
								</template>
								<template v-else>
									<span v-bind:style="tag.children.length > 0 ? 'font-weight: bold;' : ''">
										{{ tag.prefix }} {{ tag.name }}
									</span>
									<span class="float-right">
										<b-button
												@click="createTag(tag)"
												:variant="tag.children.length > 0 ? 'success' : 'light'"
												size="sm"
												class="mr-2"
										>
											<b-icon icon="plus"></b-icon>
										</b-button>
										<b-button
												:disabled="tag.depth == 0"
												@click="editTag(tag)"
												variant="primary"
												size="sm"
										>
											<b-icon icon="pencil"></b-icon>
										</b-button>
									</span>
								</template>
							</b-col>
							<b-col v-if="editableTag" cols="120">
								Kuulub kategooriasse:<br>
								<v-select v-if="$store.state.tags.list"
										  label="text"
										  :options="nonChildrenTags(tag)"
										  v-model="parentTag"
										  style="min-width: 300px; line-height: 1.6em;"
										  class="m-1"
								>
									<template #option="{ text, value, depth, is_leaf, prefix }">
										<em v-for="i in depth" style="color:#d0d0d0;">- </em>
										<em v-if="is_leaf">{{ prefix }} {{ text }}</em>
										<b v-else>{{ prefix }} {{ text }}</b>
									</template>
								</v-select>
								<div class="text-right">
									<b-button
											@click="cancelEdit(tag, tagKey)"
											variant="light"
											size="sm"
											class="mr-2"
									>
										Tühista
									</b-button>
									<b-button
										v-if="tag.children.length == 0 && tag.id > 0"
										@click="deleteTag(tag)"
										variant="danger"
										size="sm"
										class="mr-2"
									>
										Kustuta
									</b-button>
									<b-button @click="saveTag(tag, parentTag)"
											  variant="success"
											  size="sm"
									>
										Salvesta
									</b-button>
								</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-list-group-item>
		</b-overlay>
		<category-line v-for="(tag, tIdx) in tag.children"
					   :key="tIdx"
					   :tag-key="tIdx"
					   :tag="tag"
		></category-line>
	</div>
</template>

<script>
	import CategoryLine from '@/components/CategoryLine.vue';
	import _ from 'lodash';
	import Swal from 'sweetalert2';
	import axios from "axios";
	import {eventBus} from '@/eventbus';

	export default {
		name: 'CategoryLine',
		props: ['tag', 'tagKey'],
		components: {
			CategoryLine
		},
		data () {
			return {
				editableTag: false,
				parentTag: false,
			}
		},
		created() {
			if (!this.tag.id) {
				this.editTag(this.tag);
			}
		},
		methods: {
			editTag (tag) {
				this.parentTag = {
					text: this.$store.state.tags.tags[tag.parent_id].name,
					value: this.$store.state.tags.tags[tag.parent_id].id,
				};
				this.editableTag = JSON.parse(JSON.stringify(tag));
			},
			createTag (tag) {
				this.parentTag = {
					text: this.$store.state.tags.tags[tag.parent_id].name,
					value: this.$store.state.tags.tags[tag.parent_id].id,
				};
				let newTag = {
					id: null,
					name: '',
					parent_id: tag.id,
					depth: tag.depth + 1,
					children: [],
				};
				this.$store.state.tags.tags[tag.id].children.push(newTag);
				tag.children.push(newTag);
				eventBus.$emit('scroll-to', 'tagline-null');
			},
			getChildrenIds (tagId, ids){
				if (tagId > 0) {
					_.each(this.$store.state.tags.tags[tagId].children, function(child) {
						ids.push(child.id);
						if(child.children.length > 0){
							this.getChildrenIds(child.id, ids);
						}
					}.bind(this));
				}
				return ids;
			},
			cancelEdit(tag, key) {
				this.editableTag = false;
				this.parentTag = false;
				if (!tag.id) {
					this.$store.state.tags.tags[tag.parent_id].children.splice(key, 1);
					this.$parent._props.tag.children.splice(key, 1);
				}
			},
			saveTag(tag, parentTag) {
				this.$store.commit('setLoader', true);
				let postData = {
					tag: JSON.stringify(tag),
					parentId: parentTag.value,
				};
				axios.post('/tag', postData).then(({data}) => {
					this.editableTag = false;
					this.parentTag = false;
					if (tag.id && tag.parent_id != parentTag.value) {
						this.$store.dispatch('loadTags');
						Swal.fire({
							title: 'Kategrooria salvestatud',
							text:  '['+tag.name+'] asub nüüd ['+parentTag.text+'] all.',
							confirmButtonText: 'OK',
							confirmButtonColor: '#6f42c1',
							icon: 'success',
						});
					} else if (!tag.id) {
						this.$store.dispatch('loadTags');
						Swal.fire({
							title: 'Uus kategrooria salvestatud',
							confirmButtonText: 'OK',
							confirmButtonColor: '#6f42c1',
							icon: 'success',
						});
					} else {
						this.$store.dispatch('loadTags');
						Swal.fire({
							title: 'Kategrooria salvestatud',
							confirmButtonText: 'OK',
							confirmButtonColor: '#6f42c1',
							icon: 'success',
						});
					}
				});
			},
			deleteTag (tag) {
				let tagKey = _.findKey(this.$store.state.tags.list, {'value': tag.id});
				let traCount = this.$store.state.tags.list[tagKey].transactions_count;
				Swal.fire({
					title: 'Soovid kategooria kustutada?',
					text: traCount > 0 ? traCount+' sissekande küljest kustutatakse see kategooria ära.' : 'Ühegi sissekande küljes seda kategooriat praegu pole.',
					icon: 'error',
					showCancelButton: true,
					confirmButtonColor: '#6f42c1',
					confirmButtonText: 'Kustuta',
				}).then((result) => {
					if (result.isConfirmed) {
						axios.delete('/tag/'+tag.id).then(({data}) => {
							this.$store.dispatch('loadTags', data);
							Swal.fire({
								title: 'Kustutatud',
								confirmButtonText: 'OK',
								confirmButtonColor: '#6f42c1',
								icon: 'success',
							});
						});
					}
				});
			},
			nonChildrenTags(tag) {
				let childrenIds = this.getChildrenIds(tag.id, [tag.id]);
				return _.filter(this.$store.state.tags.list, function(tag) {
					return _.indexOf(childrenIds, tag.value) === -1;
				}.bind(this));
			}
		}
	}
</script>
