<template>
    <b-container>
		<h1>Kategooriad</h1>

		<b-list-group v-if="$store.state.rules && $store.state.tags.tree">
			<b-overlay :show="$store.state.loader" rounded="sm">
				<b-list-group-item variant="dark">
					<b-row>
						<b-col>
							Kategooriapuu haldus
						</b-col>
					</b-row>
				</b-list-group-item>
			</b-overlay>

			<category-line v-for="(tag, tIdx) in $store.state.tags.tree"
						   :key="tIdx"
						   :tag="tag"
			></category-line>

		</b-list-group>

    </b-container>
</template>

<script>

	import _ from 'lodash';
	import Swal from 'sweetalert2';
	import axios from 'axios';
	import CategoryLine from '@/components/CategoryLine.vue';
	import {eventBus} from '@/eventbus';

	export default {
		components: {
			CategoryLine
		},
		data () {
			return {
			}
		},
		created() {
			this.$store.dispatch('loadTags');
			eventBus.$on('scroll-to', key => {
				setTimeout(function() {
					document.getElementById(key).scrollIntoView({behavior: 'smooth'});
				}, 100);
			});
		},
		methods: {
		}
	}
</script>

<style>
	.vs--searchable .vs__dropdown-toggle {
		height: 38px;
	}
</style>
